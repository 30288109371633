import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from '../components/layout'
import Switcher from '../components/Switcher'

const Owners = ({ data }) => (
    <Layout>
        <Helmet title="Simplify Your Business - All you need in one place" defer={false} />
        {data.strapiPage.content.map((item, index) =>
            <Switcher key={index} data={item} />
        )}
    </Layout>
)

export default Owners

export const pageQuery = graphql`
    query Owners {
        strapiPage(title: {eq: "owners"}) {
            title
            id
            content {
                imgMaxHeight
                subtitle
                height
                width
                background
                color
                btnLink
                btnText
                title
                id
                text
                url
                imgPosition
                imgPerson {
                    publicURL
                }
                imgCompany {
                    publicURL
                }
                namePerson
                nameCompany
                cards {
                    id
                    text
                    title
                    border
                    btnLink
                    btnText
                    image {
                        publicURL
                    }
                }
                items {
                    text
                    title
                    btnLink
                    btnText
                    main
                    video
                    image {
                        publicURL
                    }
                }
                image {
                    publicURL
                }
                type {
                    type
                }
            }
        }
    }
`
